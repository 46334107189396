exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-academica-js": () => import("./../../../src/pages/academica.js" /* webpackChunkName: "component---src-pages-academica-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-business-automation-js": () => import("./../../../src/pages/services/business-automation.js" /* webpackChunkName: "component---src-pages-services-business-automation-js" */),
  "component---src-pages-services-corporate-branding-js": () => import("./../../../src/pages/services/corporate-branding.js" /* webpackChunkName: "component---src-pages-services-corporate-branding-js" */),
  "component---src-pages-services-digital-marketing-js": () => import("./../../../src/pages/services/digital-marketing.js" /* webpackChunkName: "component---src-pages-services-digital-marketing-js" */),
  "component---src-pages-services-ecommerce-development-js": () => import("./../../../src/pages/services/ecommerce-development.js" /* webpackChunkName: "component---src-pages-services-ecommerce-development-js" */),
  "component---src-pages-services-graphics-designing-js": () => import("./../../../src/pages/services/graphics-designing.js" /* webpackChunkName: "component---src-pages-services-graphics-designing-js" */),
  "component---src-pages-services-it-consultancy-js": () => import("./../../../src/pages/services/it-consultancy.js" /* webpackChunkName: "component---src-pages-services-it-consultancy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-managed-cloud-services-js": () => import("./../../../src/pages/services/managed-cloud-services.js" /* webpackChunkName: "component---src-pages-services-managed-cloud-services-js" */),
  "component---src-pages-services-managed-it-infrastructure-js": () => import("./../../../src/pages/services/managed-it-infrastructure.js" /* webpackChunkName: "component---src-pages-services-managed-it-infrastructure-js" */),
  "component---src-pages-services-mobile-apps-development-js": () => import("./../../../src/pages/services/mobile-apps-development.js" /* webpackChunkName: "component---src-pages-services-mobile-apps-development-js" */),
  "component---src-pages-services-seo-search-engine-optimization-js": () => import("./../../../src/pages/services/seo-search-engine-optimization.js" /* webpackChunkName: "component---src-pages-services-seo-search-engine-optimization-js" */),
  "component---src-pages-services-web-designing-js": () => import("./../../../src/pages/services/web-designing.js" /* webpackChunkName: "component---src-pages-services-web-designing-js" */),
  "component---src-pages-services-web-development-js": () => import("./../../../src/pages/services/web-development.js" /* webpackChunkName: "component---src-pages-services-web-development-js" */),
  "component---src-pages-support-faq-js": () => import("./../../../src/pages/support/faq.js" /* webpackChunkName: "component---src-pages-support-faq-js" */),
  "component---src-pages-support-grievance-js": () => import("./../../../src/pages/support/grievance.js" /* webpackChunkName: "component---src-pages-support-grievance-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

